body{
  /* font-family: "Open Sans", sans-serif; */
  font-family: 'Roboto', sans-serif;
  /* font-family: "Roboto", serif; */
  font-weight: 500;
  font-style: normal;
  background: rgb(215,215,215);
  background: radial-gradient(circle, rgba(215,215,215,1) 81%, rgba(195,195,195,1) 100%);
}

.App-body {
    text-align: center;
    margin: auto;
    max-width: 60vh;
    background-position: center;
    background-size: cover;
    background: #fff;
    min-height: 100vh;
    z-index: 1;
    height: 130vh;
  }

.d-none {
  display: none;
}
.bold-text {
  font-weight: 600 !important;
}
.centered {
  display: flex;
  justify-content: center;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.button-wid {
 width: 23vh !important;

}
#Display, #Header {
  z-index: 2;
  position: relative;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  color: rgb(0, 0, 0);
  font-size: 25px;
  text-align: center;
  margin: auto;
  max-width: 60vh;
  background-position: center;
  background-size: cover;
  background: #fff;
  z-index: 3;
}
.sticky-button {
  z-index: 4; /* Adjust the z-index value as needed */
  position: relative;
}
/* Page Layout */
#page {
  display: grid;
  margin: auto;
  max-width: 720px;
  height: 100vh;
  grid-template:
    "head" 12%
    "main" 53%
    "nav" 10%
    "foot" 25% / 1fr;
}

header {
  grid-area: head / head / head / head;
  justify-self: stretch;
}

main {
  grid-area: main ;
  display: flex;
  flex-direction: column;
}

nav {
  grid-area: nav / nav / nav / nav;
  justify-self: stretch;
} 

footer {
  grid-area: foot / foot/ foot / foot;
  justify-self: stretch;
  margin-top: auto;
}


/* PDF Styles */
.react-pdf__Document {
  width: 60vh !important;
}
/* .react-pdf__Page__textContent.textLayer {
  width: 60vh !important;
} */
.react-pdf__Page__annotations.annotationLayer {
  width: 60vh !important;
}
.react-pdf__Page__canvas {
  max-width: 60vh !important;
  width: 600px !important;
}
.arrow-button {
  display: flex;
  color: #fff;
  background-color: #0073b1;
  padding: 10px 16px;
  border-radius: 20px;
  transition: all .3s ease;
  font-weight: bold;
  cursor: pointer;
  font-size: 2vh;
  align-items: center;
  border: none;
}

.arrow-button > .arrow {
  width: 6px;
  height: 6px;
  border-right: 2px solid #C2FFE9;
  border-bottom: 2px solid #C2FFE9;
  position: relative;
  transform: rotate(130deg);
  margin: 0 18px;
  transition: all .3s ease;
  font-size: 2vh;
}

.arrow-button > .arrow::before {
  display: block;
  background-color: currentColor;
  width: 3px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 0;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all .3s ease;
  content: "";
  right: 0;
}

.arrow-button:hover > .arrow {
  transform: rotate(130deg) translate(4px, 4px);
  border-color: text-hover-color;
}

.arrow-button:hover > .arrow::before {
  opacity: 1;
  width: 8px;
}

.arrow-button:hover {
  background-color: #AD00B1;
  color: #fff;
}

#x {
  position: absolute;
  background: red;
  color: white;
  top: -10px;
  right: -10px;
}

  /* Error Page & Spinner Page */
  .error , .spinner , .return{
    text-align: center;
    margin: auto;
    max-width: 60vh;
    background-position: center;
    background-size: cover;
    background: #fff;
    min-height: 100vh;
    z-index: 6;
    position: relative;
}
.spinner #page{
  grid-template:
  "head" 5%
  "main" 70%
  "nav" 10%
  "foot" 15% / 1fr;
}
.error-v #page{
  grid-template:
  "head" 5%
  "main" 70%
  "nav" 10%
  "foot" 15% / 1fr;
}

  /* Boxed */
  .box-height-1{
    height: 10vh !important;
}

  .box-height {
    height: 12vh !important;
}
  
  /* .boxed, .faded-in{ 
    opacity: 0; 
  } */

  .example-style {
    animation: fade-in 2s;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }

  }

  /* Responsive Queries */
  @media screen and (max-width: 480px) {
    /* .boxed{
      max-width: 48vh ;
      height: 31vh;
    } */
    .box-height-1{
      height: 19vh !important;
  }
  .ReactModal__Body--open .ReactModal__Content.ReactModal__Content--after-open {
    background: rgb(255, 255, 255);
    position: absolute;
    inset: 0px;
    margin: auto;
    width: 70vw;
    height: 89vh;
    padding: 20px;
    white-space: pre-wrap;
  }
  /* PDF Styles */
.react-pdf__Document {
  width: 45vh !important;
  /* height: 75vh !important; */
}
.react-pdf__Page__textContent.textLayer {
  width: 45vh !important;
  /* height: 75vh !important; */
}
.react-pdf__Page__annotations.annotationLayer {
  width: 45vh !important;
  /* height: 75vh !important; */
}
.react-pdf__Page__canvas {
  max-width: 45vh !important;
  width: 600px !important;
  /* height: 75vh !important; */
}
  
  }
